import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#FFFFFF',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 337.46 428.5"
    >
      <path className={classes.path} d="M439,403v38.44c-1,6.18-1.43,12.49-3,18.51-6,22.87-13,45.47-25.64,65.62-12.38,19.68-26.15,38.36-46.1,51.74l-90.95-71.9c20.46-17.75,36.35-37,46.41-61.19s11-48.92,8.18-73.91C325,344.62,312.73,322.43,297.21,302c-28.19-37.08-97.37-61.08-132.6-47.62,14.1,1.32,30.49,2.89,46.89,4.36,18.14,1.63,33.4,10.3,47.71,20.47,33.72,24,52.44,56.76,56.48,98,3.25,33.09-5.37,63.06-24.05,90.05-11.38,16.45-25.84,30.13-43.83,39.43-26.39,13.65-53.75,21.62-84.13,16.48-32.71-5.54-60.46-19.44-82.41-44.42-19.12-21.78-30.46-46.64-33-76A152.21,152.21,0,0,1,52,351.89c5.79-22.84,18.86-42.78,35.05-59.81a301.15,301.15,0,0,1,40.28-35.67c28.49-20.81,61.27-31.13,96.27-33.87,23.58-1.84,46.51,1.11,69.58,7.53a214.28,214.28,0,0,1,43.67,18c25.83,13.77,46.91,33.28,64,56.9a194.78,194.78,0,0,1,33.34,75.2C435.76,387.77,437.39,395.37,439,403Z" transform="translate(-47.58 -222)"/>
    </svg>
  );
};

export default LogoIcon;
